import React, { useState, useEffect } from 'react'
import Select2 from 'react-select'
import regionStore from '../stores/region'

export default function ({onChange, value}) {

  const [defaultCategory, setDefaultCategory] = useState(null);

  const categories = [
    { value: '', label: '- select -'},
    { value: 'Field Team Gear Request', label: 'Field Team Gear Request' },
    { value: 'Gear Store Order', label: 'Gear Store Order' },
    { value: 'Inventory Adjustment', label: 'Inventory Adjustment' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'New Hires', label: 'New Hires' },
    { value: 'Ops Pack', label: 'Ops Pack' },
    { value: 'One-Off', label: 'One-Off' },
    { value: 'Pre-Trip Package', label: 'Pre-Trip Package' },
    { value: 'ZLIND', label: 'ZLIND' },
  ]

  useEffect(() => {
    setDefaultCategory(categories.find((r) => r.value == value))
  }, [value])

  return (
    <div className="mb-4">
      <label className="block font-bold">Category</label>
      <Select2
        options={categories}
        onChange={onChange}
        menuPlacement="top"
        value={defaultCategory}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isClearable
      />
    </div>
  )
}
