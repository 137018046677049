import React, { useEffect, useState } from 'react'
import Table from './components/Table'
import Button from './components/Button'
import Modal from './components/Modal'
import transactionStore from './stores/transaction'
import { produce } from 'immer'
import { Text, TextArea, Radios } from './components/Fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const PurchaseOrderRow = ({ transaction }) => {
  const [editing, setEditing] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [transactionToEdit, setTransactionToEdit] = useState({})
  const [open, setOpen] = useState(false)
  const [minimized, setMinimized] = useState(true)
  const [error, setError] = useState(false)
  const [receiving, setReceiving] = useState(false)
  const [
    currentItems, 
    direction,
    setDirection, 
    purchaseOrderId,
    setPurchaseOrderId, 
    addToTransaction, 
    updateTransaction, 
    deleteTransaction,
    clear,
    closed, 
    setClosed
  ] = transactionStore(state => [
      state.items, 
      state.direction,
      state.setDirection, 
      state.purchase_order_id,
      state.setPurchaseOrderId, 
      state.add, 
      state.update, 
      state.delete,
      state.clear,
      state.closed,
      state.setClosed
    ])

  const faTrash = icon({ name: 'trash' })
  const pencil = icon({ name: 'pencil' })

  function cancel(e) {
    e.preventDefault()
    setOpen(false)
    setEditing(false)
  }

  function edit(e) {
    e.preventDefault()
    setTransactionToEdit(transaction)
    setEditing(true)
  }

  async function save(e) {
    e.preventDefault()
    await updateTransaction(transactionToEdit)
    setEditing(false)
  }

  async function remove(e) {
    e.preventDefault()
    await deleteTransaction(transaction)
    setRemoving(false)
  }

  function receive() {
    setReceiving(false)
    setError(false)
    if(Object.keys(currentItems).length > 0) {
      return setError(true)
    }
    setMinimized(false)
    setDirection(1)
    setPurchaseOrderId(transaction.id)
    transaction.items_attributes.forEach(item => {
      addToTransaction(item.product, item.quantity)
    })
  }  

  function update(field, value) {
    setTransactionToEdit(produce(draft => {
      draft[field] = value
    }))
  }

  function updateQuantity(id, value) {
    setTransactionToEdit(produce(draft => {
      const index = draft.items_attributes.findIndex(item => item.id === id)
      draft.items_attributes[index].quantity = value
    }))
  }

  function clearCurrent() {
    setReceiving(true)
    clear()
  }

  useEffect(() => {
    if (receiving == false) return
    if (Object.keys(currentItems).length > 0) return
    receive()
  }, [currentItems])

  return (
    <Table.Row className={ transaction.closed ? '' : 'bg-red-50' }>
      <Table.Td>{transaction.id}</Table.Td>
      <Table.Td>{transaction.po_number}</Table.Td>
      <Table.Td>
        <ul className="list-disc pl-6">
          {transaction.items_attributes && transaction.items_attributes.map((item) =>
            <li key={item.id}>{item.quantity} - {item.title}</li>
          )}
        </ul>
      </Table.Td>
      <Table.Td>{transaction.expected_on}</Table.Td>
      <Table.Td>
        <ul className="list-disc pl-6">
          {transaction.purchase_order_transactions.map(transaction => 
            <li key={transaction.id}>{transaction.id}</li>
          )}
        </ul>
      </Table.Td>
      <Table.Td>
        {transaction.notes}
      </Table.Td>
      <Table.Td>
        <Button onClick={edit}>
          <FontAwesomeIcon icon={pencil} className="mr-2" />
          Edit
        </Button>
        <Modal isOpen={editing} onClose={() => setEditing(false)}>
          <Modal.Header onClose={() => setEditing(false)}>
            Editing Purchase Order #{transaction.po_number} - {transaction.created_at}
          </Modal.Header>
          <Modal.Body>
            <Text 
              label="PO Number" 
              value={transactionToEdit.po_number ?? ''} 
              onChange={e => udpate('po_number', e.target.value)}
              groupClass="mb-4"
            />
            <Text 
              label="Expected On" 
              type="date"  
              value={transactionToEdit.expected_on ?? ''} 
              onChange={e => update('expected_on', e.target.value)}
              groupClass="mb-4"
            />
            <TextArea
              label="Notes"
              value={transactionToEdit.notes ?? ''}
              onChange={e => update('notes', e.target.value)}
              groupClass="mb-4"
            />
            <h3 className="font-bold text-xl mb-2">Items</h3>
            <div className="p-2 rounded-lg bg-gray-100 mb-4">
              {transactionToEdit.items_attributes && transactionToEdit.items_attributes.map((item) => 
                <Text
                  key={item.id}
                  type="number"
                  label={item.title}
                  value={item.quantity}
                  onChange={e => updateQuantity(item.id, e.target.value)}
                  groupClass="mb-4 last:mb-0"
                  />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between">
              <a href="#" onClick={cancel}>
                Cancel
              </a>
              <Button type="success" onClick={save}>
                <FontAwesomeIcon icon={icon({name: 'save'})} className="mr-2" />
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
      <Table.Td>
        <Button type="danger" onClick={() => setRemoving(true)}>
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          Remove
        </Button>
        <Modal isOpen={removing} onClose={() => setRemoving(false)}>
          <Modal.Header onClose={() => setRemoving(false)}>
            Are you sure?
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to remove transaction #{transaction.id}? This action cannot be undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between">
              <a href="#" onClick={() => setRemoving(false)}>
                Cancel
              </a>
              <Button type="danger" onClick={remove}>
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Remove
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
      <Table.Td>
        <Button onClick={receive}>
          <FontAwesomeIcon icon={icon({name: 'inbox-in'})} className="mr-2" />
          Receive
        </Button>
        {error && 
          <Modal isOpen={error} onClose={() => setError(false)}>
            <Modal.Header onClose={() => setError(false)}>
              Error
            </Modal.Header>
            <Modal.Body>
              <p>
                You already have items in an open transaction. Would you
                like to clear that transaction?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center justify-between gap-4">
                  <a href="#" onClick={() => setError(false)}>
                    Cancel
                  </a>
                  <Button type="success" onClick={clearCurrent}>
                    Clear
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        }
        </Table.Td>
    </Table.Row>
  )
}

export default function () {
    const [collection, setCollection] = useState([])
    const [newPurchaseOrder, setNewPurchaseOrder] = useState({})
    const [errors, setErrors] = useState({})
    const [showAll, setShowAll] = useState(false)
    const [transactions, fetchTransactions] = transactionStore(state => [state.po_collection, state.poFetch])
    useEffect(() => {
      fetchTransactions()
    }, [fetchTransactions])

    useEffect(() => {
      const filteredCollection = transactions.filter(transaction => showAll || !transaction.closed)
      setCollection(filteredCollection)
    }, [transactions, showAll])

    return (
        <>
          <div className="flex justify-between gap-4 mb-4 items-end">
            <h1 className="text-4xl">Purchase Orders</h1>
            <div className="flex justify-center gap-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="show-all"
                  checked={!showAll}
                  onChange={() => setShowAll(false)}
                />
                <span className="ml-2">Open</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="show-all"
                  checked={showAll}
                  onChange={() => setShowAll(true)}
                />
                <span className="ml-2">All</span>
              </label>
            </div>
          </div>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Th>ID</Table.Th>
                <Table.Th>PO Number</Table.Th>
                <Table.Th>Product</Table.Th>
                <Table.Th>Expected</Table.Th>
                <Table.Th>Transactions</Table.Th>
                <Table.Th>Notes</Table.Th>
                <Table.Th></Table.Th>
                <Table.Th></Table.Th>
                <Table.Th></Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
          {collection.map(transaction => (
            <PurchaseOrderRow key={transaction.id} transaction={transaction} />
          ))}
            </Table.Body>
          </Table>
        </>
    )
}
