import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import transactionStore from './stores/transaction'
import regionStore from './stores/region'
import Button from './components/Button'
import Modal from './components/Modal'
import { Text, Radios } from './components/Fields'
import { produce } from 'immer'
import Table from './components/Table'
import DestinationSelect from './components/DestinationSelect'
import RegionSelect from './components/RegionSelect'
import CategorySelect from './components/CategorySelect'
import { twMerge } from 'tailwind-merge'

const TransactionRow = ({ transaction }) => {

  const [editing, setEditing] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [transactionToEdit, setTransactionToEdit] = useState({})
  const [updateTransaction, deleteTransaction] = transactionStore(state => [state.update, state.delete])

  const faTrash = icon({ name: 'trash' })
  const pencil = icon({ name: 'pencil' })

  function edit(e) {
    e.preventDefault()
    setTransactionToEdit(transaction)
    setEditing(true)
  }

  function cancel(e) {
    e.preventDefault()
    setEditing(false)
  }

  async function save(e) {
    e.preventDefault()
    await updateTransaction(transactionToEdit)
    setEditing(false)
  }

  async function remove(e) {
    e.preventDefault()
    await deleteTransaction(transaction)
    setRemoving(false)
  }

  function updateQuantity(id, value) {
    setTransactionToEdit(produce(draft => {
      const index = draft.items_attributes.findIndex(item => item.id === id)
      draft.items_attributes[index].quantity = value
    }))
  }

  function setRegion(e) {
    setTransactionToEdit(produce(draft => {
      draft.region_id = e.value
    }))
  }

  function setDestinations(value) {
    setTransactionToEdit(produce(draft => {
      draft.destination_ids = value.map(d => d.value)
    }))
  }

  function setCategory(e) {
    setTransactionToEdit(produce(draft => {
      draft.category = e.value
    }))
  }

  function onInputChange(inputValue, {action, prevInputValue}) {
    if (action === 'input-change') return inputValue;
    if (action === 'menu-close') {
      if (prevInputValue) setMenuIsOpen(true);
      else setMenuIsOpen(undefined);
    }
    return prevInputValue;
  }

  return (
    <Table.Row className={transaction.direction > 0 ? 'bg-green-50' : 'bg-red-50'}>
      <Table.Td>{transaction.id}</Table.Td>
      <Table.Td>{transaction.created_at}</Table.Td>
      <Table.Td>{transaction.direction_label}</Table.Td>
      <Table.Td>
        <ul className="list-disc pl-6">
          {transaction.items_attributes && transaction.items_attributes.map((item) =>
            <li key={item.id}>{item.product.magento_id} - {item.quantity} - {item.title}</li>
          )}
        </ul>
      </Table.Td>
      <Table.Td className="text-right">{transaction.product_count}</Table.Td>
      <Table.Td className="text-right">{transaction.total_count}</Table.Td>
      <Table.Td>
        <Button onClick={edit}>
          <FontAwesomeIcon icon={pencil} className="mr-2" />
          Edit
        </Button>
        <Modal isOpen={editing} onClose={() => setEditing(false)}>
          <Modal.Header onClose={() => setEditing(false)}>
            Editing Transaction #{transaction.id} - {transaction.created_at}
          </Modal.Header>
          <Modal.Body>
            <Radios
              label="Direction"
              value={transactionToEdit.direction}
              onChange={e => setTransactionToEdit({...transactionToEdit, direction: e.target.value})}
              groupClass="mb-4"
              options={[{text: 'Receive', value: 1}, {text: 'Ship', value: -1}]}
              />
            {transactionToEdit.direction == -1 && <>
              <CategorySelect
                onChange={setCategory}
                value={transactionToEdit.category}
              />
              <DestinationSelect
                onChange={setDestinations}
                value={transactionToEdit.destination_ids}
              />
              <RegionSelect
                onChange={setRegion}
                value={transactionToEdit.region_id}
              />
            </>}
            <h3 className="font-bold text-xl mb-2">Items</h3>
            <div className="p-2 rounded-lg bg-gray-100 mb-4">
              {transactionToEdit.items_attributes && transactionToEdit.items_attributes.map((item) => 
                <Text
                  key={item.id}
                  type="number"
                  label={item.title}
                  value={item.quantity}
                  onChange={e => updateQuantity(item.id, e.target.value)}
                  groupClass="mb-4 last:mb-0"
                  />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between">
              <a href="#" onClick={cancel}>
                Cancel
              </a>
              <Button type="success" onClick={save}>
                <FontAwesomeIcon icon={icon({name: 'save'})} className="mr-2" />
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
      <Table.Td>
        <Button type="danger" onClick={() => setRemoving(true)}>
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          Remove
        </Button>
        <Modal isOpen={removing} onClose={() => setRemoving(false)}>
          <Modal.Header onClose={() => setRemoving(false)}>
            Are you sure?
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to remove transaction #{transaction.id}? This action cannot be undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between">
              <a href="#" onClick={() => setRemoving(false)}>
                Cancel
              </a>
              <Button type="danger" onClick={remove}>
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Remove
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
    </Table.Row>
  )
}

export default function () {
  const [transactions, fetchTransactions, total, page, setPage] = transactionStore(state => [state.collection, state.fetch, state.total, state.page, state.setPage])
  const [pages, setPages] = useState([])

  useEffect(() => {
    const pages = []
    for (let i = 1; i <= Math.ceil(total / 50); i++) {
      if (i == 1 || i == Math.ceil(total / 50) || Math.abs(i - page) < 3) {
        pages.push(i)
      }
    }
    setPages(pages)
  }, [total, page])

  useEffect(() => {
    fetchTransactions(page)
  }, [page])

  return (
    <>
      <div className="flex justify-between mb-4">
        <h1 className="text-4xl">Transactions</h1>
        <ul className="flex mb-4">
          {pages.map((p, index) =>
            <React.Fragment key={p}>
              {index > 0 && pages[index - 1] != p - 1 && <li className="group"><span className="block p-3 leading-4 border group-first:rounded-l group-last:rounded-r border border-l-0 group-first:border-l">...</span></li>}
              <li className="group">
                <a onClick={() => setPage(p)} className={twMerge('block p-3 leading-4 border group-first:rounded-l group-last:rounded-r border border-l-0 group-first:border-l', p == page ? 'bg-blue-600 text-white' : 'cursor-pointer')}>{p}</a>
              </li>
            </React.Fragment>
          )}
        </ul>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Th>ID</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Direction</Table.Th>
            <Table.Th>Products</Table.Th>
            <Table.Th width="120">Product count</Table.Th>
            <Table.Th width="120">Total</Table.Th>
            <Table.Th width="103"></Table.Th>
            <Table.Th width="131"></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {transactions && transactions.map(transaction => (
            <TransactionRow key={transaction.id} transaction={transaction} />
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
